<!-- main app container -->
<!-- <div class="card m-3">
    <h5 class="card-header">User Role</h5>
    <div class="card-body register-form-body">
        <form [formGroup]="UserForm" (ngSubmit)="onSubmit()">
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Role</label>
                    <input kendoTextBox formControlName="role" class="form-control"/>
                </div>
                <div class="form-group col-5">
                    <label>User Role</label>
                    <kendo-dropdownlist formControlName="userrole"  [data]="userroles" class="form-control">
                    </kendo-dropdownlist>
                </div>
            </div>
            <div class="form-row">
              <div class="form-group col-5">
                <label>Status </label>
                <kendo-dropdownlist formControlName="status"  [data]="status" class="form-control">
                </kendo-dropdownlist>
            </div>
            <div class="form-group col-5">
                <label>Competency</label>
                <kendo-dropdownlist formControlName="competency"  [data]="competencys" class="form-control">
                </kendo-dropdownlist>
            </div>
            </div>
            <div class="form-row">
                <div class="form-group col-6">
                    <label>Remark </label>
                    <kendo-textarea formControlName="remark" class="form-control" [rows]="5" ></kendo-textarea>
                </div>
                <div class="form-group col-4">
                </div>
            </div>
            <div class="text-center mt-3 mb-4">
                <button kendoButton primary="true" class="mr-2">Save</button>
                <button kendoButton primary="true" class="mr-2">Save and Add new</button>
                <button kendoButton type="reset" (click)="onReset()">Reset</button>
            </div>
        </form>
    </div>
  </div> -->

<div class="card m-3">
    <h5 class="card-header">User Role List</h5>
    <div class="card-body">
      <app-table-grid
          [data]="gridData"
          [column]="column"
          [size]="5"
        ></app-table-grid>
      <!-- <kendo-grid [data]="gridData">
        <kendo-grid-column field="rolename" title="Role Name">
        </kendo-grid-column>
        <kendo-grid-column field="usertype" title="User Type">
        </kendo-grid-column>
        <kendo-grid-column field="competency"  title="Competency">
        </kendo-grid-column>
        <kendo-grid-column field="status" title="Status">
        </kendo-grid-column>
      </kendo-grid> -->
    </div>
</div>


<!-- main app container -->
<!-- <div class="card m-3">
    <h5 class="card-header">
        Add Permission</h5>
    <div class="card-body">
        <div class="section">
            <div class="wrap">
                <input type="checkbox" id="terms" kendoCheckBox />
              <label class="k-checkbox-label" for="terms"
                > Location</label
              >
            </div>
            <div class="wrap">
                <input type="checkbox" id="terms" kendoCheckBox />
              <label class="k-checkbox-label" for="terms"
                > Level</label
              >
            </div>
            <div class="wrap">
                <input type="checkbox" id="terms" kendoCheckBox />
              <label class="k-checkbox-label" for="terms"
                > Block</label
              >
            </div>
            <div class="wrap">
                <input type="checkbox" id="terms" kendoCheckBox />
              <label class="k-checkbox-label" for="terms"
                > Ticket Assigment</label
              >
            </div>
            <div class="wrap">
                <input type="checkbox" id="terms" kendoCheckBox />
              <label class="k-checkbox-label" for="terms"
                > All</label
              >
            </div>
            <div class="text-center">
                <button kendoButton primary="true" class="mr-2">Save</button>
                <button kendoButton primary="true" class="mr-2">Save and Add new</button>
                <button kendoButton type="reset" (click)="onReset()">Reset</button>
            </div>
          </div>
    </div>
  </div> -->
  


    

     
     


    




  










