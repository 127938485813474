import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { sampleProducts } from 'src/app/Data/table';

@Component({
  selector: 'app-contractor-vendor',
  templateUrl: './contractor-vendor.component.html',
  styleUrls: ['./contractor-vendor.component.scss']
})
export class ContractorVendorComponent implements OnInit {

  public onTabSelect(e) {
    console.log(e);
  }

  public column1=[
    {field:"ProductID",title:"Contractor/ Vendor Registration No",isFilter:true},
    {field:"ProductName",title:"Contractor/ Vendor Name", isFilter:true},
    {field:"FirstOrderedOn",title:"Country", isFilter:true},
    {field:"UnitPrice",title:"State", isFilter:true},
    {field:"UnitPrice",title:"Status", isFilter:true}
  ]
  public gridData=sampleProducts;

  contractor_vendor: FormGroup;
  submitted = false;
  status = ['Active','Inactive'];
  spec_det = ['Machanical','Electrical','Housekeeping'];
  country = ['India','Austrailia','England'];
  constructor(private formBuilder: FormBuilder) { }
  
  ngOnInit() {
    this.contractor_vendor = this.formBuilder.group({
      con_ven_r: ['', Validators.required],
      con_ven_name: ['', Validators.required],
      status: ['', Validators.required],
      spec_det: ['', [Validators.required]],
      add_1: ['', Validators.required],
      add_2: ['', Validators.required],
      post_code: ['', Validators.required],
      state: ['', [Validators.required]],
      country: ['', Validators.required],
      phn_no: ['', Validators.required],
      fax_no: ['', Validators.required],
      no_user_access: ['', [Validators.required]],
      contact_person: ['', Validators.required],
      design: ['', Validators.required],
      contact_no: ['', Validators.required],
      email: ['', Validators.required],
      remark: ['', Validators.required]
  
    }, {
        // validator: MustMatch('password', 'confirmPassword')
    });
  }
  
  // convenience getter for easy access to form fields
  get f() { return this.contractor_vendor.controls; }
  
  onSubmit() {
      this.submitted = true;
  
      // stop here if form is invalid
      if (this.contractor_vendor.invalid) {
          return;
      }
  
      // display form values on success
      alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.contractor_vendor.value, null, 4));
  }
  
  onReset() {
      this.submitted = false;
      this.contractor_vendor.reset();
  }

}
