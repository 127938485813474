import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { MessageService } from '@progress/kendo-angular-l10n';
import { DrawerComponent, DrawerMode, DrawerSelectEvent } from '@progress/kendo-angular-layout';
import { CustomMessagesService } from './services/custom-messages.service';
import {menu} from "./Data/menu"
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    public selected = 'Team';
    public items:any = menu.parents;
    public customMsgService: CustomMessagesService;
    public mode: DrawerMode = 'push';
    public mini = true;
    public auth=false;
    public itemIndex;
    constructor(private router: Router, public msgService: MessageService) {
        this.customMsgService = this.msgService as CustomMessagesService;

        // setTimeout(()=>{
        //     this.auth=true
        // },1000)

    }

    ngOnInit() {
    }
    loginStatus(status){
        this.auth=status
    }
    ngOnDestroy() {
        // window.removeEventListener('resize', () => {});
    }

    public setDrawerConfig() {
        const pageWidth = window.innerWidth;
        if (pageWidth <= 770) {
            this.mode = 'overlay';
            this.mini = false;
        } else {
            this.mode = 'push';
            this.mini = true;
        }
    }

 
    public toggleDrawer(drawer: DrawerComponent): void {
        drawer.toggle();
    }

    public onSelect(ev: DrawerSelectEvent): void {
        this.selected = ev.item.text;
        const item = this.items.find((e, index) => {
          this.itemIndex = index;
          return e.text === ev.item.text;
        });
    
        item.expanded ? (item.expanded = false) : (item.expanded = true);
        if(ev.item.childrenList && ev.item.childrenList.length>0){
            item.expanded
            ? this.addChildren(ev.item.childrenList)
            : this.removeChildren(ev.item.childrenList);
        }
        if(ev.item.path){
        this.router.navigate([ev.item.path]);
        }
    }
    public addChildren(children) {
        this.items.splice(this.itemIndex + 1, 0, ...children);
      }
    
      public removeChildren(children: Array<any>) {
        this.items.splice(this.itemIndex + 1, children.length);
      }
    private baseImageUrl =
    "https://demos.telerik.com/kendo-ui/content/web/panelbar/";

  public imageUrl(imageName: string): string {
    return this.baseImageUrl + imageName + ".jpg";
  }
}
