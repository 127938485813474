<div class="card m-3">
    <h5 class="card-header">Asset Type Code Classification </h5>
    <div class="card-body register-form-body">
        <form [formGroup]="Asset_type_cl" (ngSubmit)="onSubmit()">
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Asset Classification Code</label>
                    <input kendoTextBox formControlName="asset_code" class="form-control"/>
                </div>
                <div class="form-group col-5">
                    <label>Asset Classification Description</label>
                    <input kendoTextBox formControlName="asset_dis" class="form-control"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Asset Type Code</label>
                    <input kendoTextBox formControlName="asset_cla_dis" class="form-control"/>
                </div>
                <div class="form-group col-5">
                    <label>Asset Type Description</label>
                    <input kendoTextBox formControlName="asset_type_code" class="form-control"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Maintenence Flag</label>
                    <kendo-dropdownlist formControlName="maintenence_f"  [data]="maintenence_f" class="form-control">
                    </kendo-dropdownlist>
                </div>
                <div class="form-group col-5">
                    <label>Equipment Function Description</label>
                    <input kendoTextBox formControlName="equip_fun_d" class="form-control"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Life Expectency</label>
                    <input kendoTextBox formControlName="life_ex" class="form-control"/>
                </div>
                <div class="form-group col-5">
                    <label>Expected Life Span</label>
                    <input kendoTextBox formControlName="exp_l_s" class="form-control"/>
                </div>
            </div>
            <div class="px-3 pt-4 pb-4">
                <h3 class="font-weight-bold h3-heading">QAP Details</h3>
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>QAP Asset Service</label>
                    <input kendoTextBox formControlName="qap_asswt_ser" class="form-control"/>
                </div>
                <div class="form-group col-5">
                    <label>PPM Completion</label>
                    <input kendoTextBox formControlName="ppm_compl" class="form-control"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Equipment Uptime</label>
                    <input kendoTextBox formControlName="equip_up" class="form-control"/>
                </div>
                <div class="form-group col-5">
                    <label>QAP Uptime Target</label>
                    <input kendoTextBox formControlName="qap_up_tar" class="form-control"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Effective Form</label>
                    <input kendoTextBox formControlName="effe_form" class="form-control"/>
                </div>
                <div class="form-group col-5">
                    <label>Effective To</label>
                    <input kendoTextBox formControlName="effec_to" class="form-control"/>
                </div>
            </div>
            <div class="px-3 pt-4 pb-4">
                <h3 class="font-weight-bold h3-heading">Uptime Target</h3>
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Asset Age < 5 Yrs (%)</label>
                    <input kendoTextBox formControlName="asset_age_5" class="form-control"/>
                </div>
                <div class="form-group col-5">
                    <label>Asset Age > 10 Yrs (%)</label>
                    <input kendoTextBox formControlName="asset_age_10" class="form-control"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Asset Age 5 - 10 Yrs (%)</label>
                    <input kendoTextBox formControlName="asset_age_5g" class="form-control"/>
                </div>
                <div class="form-group col-5">
                </div>
            </div>

            <div class="px-3 pt-4 pb-4">
                <h3 class="font-weight-bold h3-heading">Variation Date Detail</h3>
                <div class="form-group float-right">
					<label class="k-label pr-4 mr-2">Variation Date Details Required &nbsp;&nbsp;
						<input type="checkbox" kendoCheckBox />
					</label>
				</div>
            </div>
            <div class="px-5 py-3">
                <app-table-grid
                [data]="gridDataassettype"
                [column]="column_asset_type"
                [size]="5"
            ></app-table-grid>
            </div>
            <div class="text-center mb-4 mt-4">
                <button kendoButton primary="true" class="mr-2">Save</button>
                <button kendoButton primary="true" class="mr-2">Save and add new</button>
                <button kendoButton type="reset" (click)="onReset()">Reset</button>
            </div>
        </form>
       
    </div>
  </div>
