import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-login-user',
  templateUrl: './login-user.component.html',
  styleUrls: ['./login-user.component.scss']
})
export class LoginUserComponent implements OnInit {
 type="login";
 @Output() loginStatus=new EventEmitter<boolean>();
  constructor() { }  
   
  ngOnInit(): void {
  }
  
  loginResponce(status){
    this.loginStatus.emit(true)
  }

}
