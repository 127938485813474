<!-- main app container -->
<div class="card m-3">
    <h5 class="card-header">User Shift And Leave Details</h5>
    <div class="card-body register-form-body">
        <form [formGroup]="user_shift_form"  (ngSubmit)="onSubmit()">
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Staff Name<span style="color: red;">*</span></label>
                    <input kendoTextBox formControlName="staff_name" class="form-control">
                </div>
                <div class="form-group col-5">
                   
                </div>
            </div>
            
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Mobile No </label>
                    <input kendoTextBox formControlName="mob_no" class="form-control">
                </div>
                <div class="form-group col-5">
                    <label>User Type </label>
                    <input kendoTextBox formControlName="user_type" class="form-control">
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Designation </label>
                    <input kendoTextBox formControlName="design" class="form-control">
                </div>
                <div class="form-group col-5">
                   
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Shift Time<span style="color: red;">*</span></label>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label style="min-width: 40px;">To</label>
                            <kendo-timepicker [(value)]="valuetime"></kendo-timepicker>
                            <!-- <input kendoTextBox formControlName="todate" class="form-control"> -->
                        </div>
                        <div class="form-group col-12">
                            <label style="min-width: 45px;">From</label>
                            <kendo-timepicker [(value)]="valuetime"></kendo-timepicker>
                            <!-- <input kendoTextBox formControlName="fromdate" class="form-control"> -->
                        </div>
                    </div>
                </div>
                <div class="form-group col-5">
                    <label>Break Time<span style="color: red;">*</span> </label>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label style="min-width: 40px;">To</label>
                            <kendo-timepicker [(value)]="valuetime"></kendo-timepicker>
                            <!-- <input kendoTextBox formControlName="todate" class="form-control"> -->
                        </div>
                        <div class="form-group col-12">
                            <label style="min-width: 45px;">From</label>
                            <kendo-timepicker [(value)]="valuetime"></kendo-timepicker>
                            <!-- <input kendoTextBox formControlName="fromdate" class="form-control"> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-4">
                <h3 class="font-weight-bold">Leave Details</h3>
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Leave from </label>
                    <kendo-datepicker [(value)]="value"></kendo-datepicker>
                    <!-- <input kendoTextBox formControlName="leavef" class="form-control"> -->
                </div>
                <div class="form-group col-5">
                    <label>Leave To </label>
                    <kendo-datepicker [(value)]="value2"></kendo-datepicker>
                    <!-- <input kendoTextBox formControlName="leavet" class="form-control"> -->
                </div>
            </div>
            

        </form>
      
    </div>
  </div>

  <!-- main app container -->
<div class="card m-3">
    <div class="card-body">
        <!-- <kendo-grid [data]="leavegridData">
            <kendo-grid-column field="leavefrom" title="Leave From">
            </kendo-grid-column>
            <kendo-grid-column field="leaveto" title="Leave to">
            </kendo-grid-column>
            <kendo-grid-column field="no_days"  title="No Of Days">
            </kendo-grid-column>
          </kendo-grid> -->
          <app-table-grid
          [data]="gridData"
          [column]="column1"
          [size]="5"
        ></app-table-grid>
    </div>
</div>

<div class="card m-3">
    <h5 class="card-header">Summary</h5>
    <div class="card-body">
          <app-table-grid
          [data]="gridData"
          [column]="column2"
          [size]="5"
        ></app-table-grid>
    </div>
</div>
  
  