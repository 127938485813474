<div class="card m-3">
    <h5 class="card-header">Asset Classification</h5>
    <div class="card-body register-form-body">
        <form [formGroup]="Asset_cl" (ngSubmit)="onSubmit()">
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Asset Classification Code</label>
                    <input kendoTextBox formControlName="asset_code" class="form-control"/>
                </div>
                <div class="form-group col-5">
                    <label>Asset Classification Description</label>
                    <!-- <kendo-dropdownlist formControlName="userrole"  [data]="userroles" class="form-control">
                    </kendo-dropdownlist> -->
                    <input kendoTextBox formControlName="asset_dis" class="form-control"/>
                </div>
            </div>
            <div class="form-row">
              <div class="form-group col-5">
                <label>Status </label>
                <kendo-dropdownlist formControlName="status"  [data]="status" class="form-control">
                </kendo-dropdownlist>
            </div>
            <div class="form-group col-5">
                
            </div>
            </div>
            <div class="form-row">
                <div class="form-group col-11 p-3">
                    <label>Remark </label>
                    <kendo-textarea formControlName="remark" class="form-control" [rows]="5" ></kendo-textarea>
                </div>
                <!-- <div class="form-group col-1">
                </div> -->
            </div>

            <div class="text-center mb-4 pt-3">
                <button kendoButton primary="true" class="mr-2">Save</button>
                <button kendoButton primary="true" class="mr-2">Save and add new</button>
                <button kendoButton type="reset" (click)="onReset()">Reset</button>
            </div>
        </form>
        <div class="py-3 px-5">
            <app-table-grid
            [data]="gridDataasset"
            [column]="column_asset"
            [size]="5"
        ></app-table-grid>
        </div>
    </div>
  </div>
