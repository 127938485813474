import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { sampleProducts } from 'src/app/Data/table';

@Component({
  selector: 'app-asset-classification',
  templateUrl: './asset-classification.component.html',
  styleUrls: ['./asset-classification.component.scss']
})
export class AssetClassificationComponent implements OnInit {


  public column_asset=[
    {field:"ProductID",title:"Asset Classification Code",isFilter:true},
    {field:"ProductName",title:"Asset Classification Description", isFilter:true},
    {field:"FirstOrderedOn",title:"Status", isFilter:true},
    {field:"UnitPrice",title:"Remark", isFilter:true},
    {field:"UnitPrice",title:"Action", isFilter:true}
  ]
  public gridDataasset=sampleProducts;
  // constructor() { }

Asset_cl: FormGroup;
  submitted = false;
  status = ['Active','Inactive'];
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.Asset_cl = this.formBuilder.group({
        asset_code: ['', Validators.required],
        asset_dis: ['', Validators.required],
        status: ['', [Validators.required]],
        remark: ['', Validators.required],

    }, {
        // validator: MustMatch('password', 'confirmPassword')
    });
}

  // convenience getter for easy access to form fields
  get f() { return this.Asset_cl.controls; }

  onSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      if (this.Asset_cl.invalid) {
          return;
      }

      // display form values on success
      alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.Asset_cl.value, null, 4));
  }

  onReset() {
      this.submitted = false;
      this.Asset_cl.reset();
  }

}