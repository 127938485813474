<kendo-grid
[data]="gridData"
[pageSize]="state.take"
[skip]="state.skip"
[sort]="state.sort"
[filter]="state.filter"
[sortable]="true"
[pageable]="true"
filterable="menu"
(dataStateChange)="dataStateChange($event)"
>
<kendo-grid-column
  *ngFor="let col of column"
  field={{col.field}}
  title={{col.title}}
  width={{col.width}}
  [filterable]="col.isFilter"
  filter={{col.filter}}
>
</kendo-grid-column>
<!-- <kendo-grid-column field="ProductName" title="Product Name">
</kendo-grid-column>
<kendo-grid-column
  field="FirstOrderedOn"
  title="First Ordered On"
  width="240"
  filter="date"
  format="{0:d}"
>
</kendo-grid-column>
<kendo-grid-column
  field="UnitPrice"
  title="Unit Price"
  width="180"
  filter="numeric"
  format="{0:c}"
>
</kendo-grid-column> -->
<!-- <kendo-grid-column field="Discontinued" width="120" filter="boolean">
  <ng-template kendoGridCellTemplate let-dataItem>
    <input type="checkbox" [checked]="dataItem.Discontinued" disabled />
  </ng-template>
</kendo-grid-column> -->
</kendo-grid>