import { Team } from 'src/app/models/team.model';

export const teams: Team[] = [
    {
        teamId: 3,
        id: '848e6002-8a92-447d-951b-1ffd5e695578',
        fullName: 'Sig Jeannel',
        jobTitle1: 'Human Resources Assistant III',
        phone1: '(936) 9429601',
        address1: '138 Buhler Avenue',
       
    },
    {
        teamId: 3,
        id: '19d18d40-0e64-4837-9420-92130a0ed253',
        fullName: 'Shelden Greyes',
        jobTitle1: 'Operator',
        phone1: '(343) 6656271',
        address1: '2 Waxwing Point'
    },
    {
        teamId: 4,
        id: 'bebdc6eb-9179-484a-917d-2e16a23bfdfe',
        fullName: 'Megen Cody',
        jobTitle1: 'Operator',
        phone1: '(178) 2336256',
        address1: '4082 Stephen Court'
    },
    {
        teamId: 1,
        id: '38b08b88-e482-46fc-8976-83590c02ec23',
        fullName: 'Clevey Thursfield',
        jobTitle1: 'VP Quality Control',
        phone1: '(277) 7415010',
        address1: '1563 Glacier Hill Parkway'
    },
    {
        teamId: 2,
        id: '2aac53f8-b72d-4629-9082-6d8239a8fecf',
        fullName: 'Ruthi Baldini',
        jobTitle1: 'Data Coordiator',
        phone1: '(766) 5691615',
        address1: '6 Laurel Avenue'
    },
    {
        teamId: 5,
        id: '1aa789e5-de01-406e-a2ee-cc5ce20f7e34',
        fullName: 'Annecorinne Morter',
        jobTitle1: 'Professor',

        phone1: '(807) 2524830',
        address1: '6 Laurel Avenue'
    },
    {
        teamId: 2,
        id: 'd2ff1b02-3808-44aa-9056-3b5df34bf865',
        fullName: 'Gracia Punyer',
        jobTitle1: 'Assistant Manager',
        phone1: '(515) 9749536',
        address1: '69 Brentwood Alley'
    },
    {
        teamId: 2,
        id: '26b2b760-27e8-47a6-81c2-07870d1b2b30',
        fullName: 'Duky Hurring',
        jobTitle1: 'Account Executive',
        phone1: '(897) 7202034',
        address1: '6 Laurel Avenue'
    },
    {
        teamId: 1,
        id: '91c6b652-4206-4a0c-bac6-dc21283a72d7',
        fullName: 'Briana Shemelt',
        jobTitle1: 'Professor',

        phone1: '(205) 2560799',
        address1: '6 Laurel Avenue'
    },
    {
        teamId: 4,
        id: '1e8289dc-2ef3-4045-ad6b-786d00368427',
        fullName: 'Lexis Mostin',
        jobTitle1: 'Analyst Programmer',
        phone1: '(903) 8388089',
        address1: '38547 Westend Way'
    },
    {
        teamId: 2,
        id: '797387bd-c247-48b3-97b6-5e75791f8809',
        fullName: 'Felizio Gooda',
        jobTitle1: 'GIS Technical Architect',
        phone1: '(372) 2389397',
        address1: '9 Summer Ridge Circle'
    },
    {
        teamId: 2,
        id: '24c541b0-4978-4072-84d0-abe94fcd0756',
        fullName: 'Aubry Oxberry',
        jobTitle1: 'Financial Advisor',
        phone1: '(665) 4176083',
        address1: '06 Lerdahl Point',
    },
    {
        teamId: 1,
        id: 'd3416440-7411-42cc-a913-7dd319ca8311',
        fullName: 'Orly Glasbey',
        jobTitle1: 'Environmental Tech',
        phone1: '(449) 8482879',
        address1: '4035 Porter Parkway'
    },
    {
        teamId: 4,
        id: '139066b5-60c5-4cf5-9afe-fb4e5558b087',
        fullName: 'Stephanus Culp',
        jobTitle1: 'Cost Accountant',
        phone1: '(148) 3124030',
        address1: '6 Laurel Avenue'
    },
    {
        teamId: 2,
        id: 'eb844971-b97d-4f79-bd5a-a266fcfaaf70',
        fullName: 'Roseanna Janecek',
        jobTitle1: 'Database Administrator IV',
        phone1: '(125) 4421623',
        address1: '6 Laurel Avenue'
    },
    {
        teamId: 1,
        id: '5cb391fe-4855-445c-a8b8-617c04d1d999',
        fullName: 'Weidar McCombe',
        jobTitle1: 'Civil Engineer',
        phone1: '(488) 7911627',
        address1: '7 Dahle Terrace'
    },
    {
        teamId: 4,
        id: '4ba9ad7e-d8b7-40e7-b8cd-67a8e743a249',
        fullName: 'Evelin Spirritt',
        jobTitle1: 'Analyst Programmer',

        phone1: '(821) 9538078',
          address1: '6 Laurel Avenue'
    },
    {
        teamId: 2,
        id: 'e4a31407-39d1-4ab7-aad1-4e026a8c42fa',
        fullName: 'Andria Helbeck',
        jobTitle1: 'Nurse Practicioner',
        phone1: '(387) 9614638',
        address1: '8589 Vernon Drive'
    },
    {
        teamId: 1,
        id: 'cd050499-dbf1-4a43-86ab-54feaacef401',
        fullName: 'Mariellen Ravelus',
        jobTitle1: 'Systems Administrator I',
        phone1: '(300) 6741661',
        address1: '707 Gale Hill'
    },
    {
        teamId: 3,
        id: 'fda1c419-c0b8-4278-94b0-2ebef6bda164',
        fullName: 'Corri Pergens',
        jobTitle1: 'Help Desk Operator',
        phone1: '(769) 7145603',
        address1: '856 Forest Crossing'
    },
    {
        teamId: 3,
        id: '4a962db9-136e-4fcc-a683-2f961e27fb6e',
        fullName: 'Friedrick Macknish',
        jobTitle1: 'Human Resources Assistant II',
        phone1: '(196) 4156385',
        address1: '157 Talisman Trail'
    },
    
    {
        teamId: 4,
        id: '67656444-fbcd-4813-84e7-1bdcd82f803e',
        fullName: 'Vanya Goalby',
        jobTitle1: 'Senior Cost Accountant',

        phone1: '(496) 7538982',
        address1: '6 Laurel Avenue'
    },
    {
        teamId: 1,
        id: '05c098c2-9617-4e66-928a-e29b97297138',
        fullName: 'Abel Ansell',
        jobTitle1: 'Actuary',

        phone1: '(571) 9908377',
        address1: '6 Laurel Avenue'
    },
    {
        teamId: 2,
        id: '3b263e99-51bf-4461-bb20-d4303d326cd9',
        fullName: 'Odille Barus',
        jobTitle1: 'Speech Pathologist',
        phone1: '(974) 1137672',
        address1: '496 Lotheville Avenue'
    },
    {
        teamId: 11,
        id: 'c09ddfaf-569e-4a75-8e53-1be27cf25927',
        fullName: '0001',
        jobTitle1: 'mr. ram',
        phone1: 'tap not working',
        address1: 'done'
    },
    {
        teamId: 12,
        id: 'a48abbc9-6143-4f77-a720-0b76ad862a9a',
        fullName: 'Super Admin',
        jobTitle1: 'false',
        phone1: '-',
        address1: '-'
    },
];
