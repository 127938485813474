import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableGridComponent } from './table-grid/table-grid.component';
import { GridModule } from "@progress/kendo-angular-grid";



@NgModule({
  declarations: [
    TableGridComponent
  ],
  imports: [
    CommonModule,
    GridModule
  ],
  exports: [
    TableGridComponent
  ]
})
export class ToolsModule { }
