<div class="profile-page main-content">
    <div class="card-container">
        <div class="card-component">
            <form class="k-form k-form-horizontal" style="max-width: 1200px" [formGroup]="formGroup">
                <fieldset class="k-form-fieldset">
                    <div style="display: flex;justify-content: space-between;">
                    <kendo-formfield orientation="horizontal">
                        <kendo-label class="k-label" [for]="firstName" text="Asset Classification Code"> </kendo-label>
                        <kendo-textbox formControlName="firstName" [clearButton]="false" #firstName required> </kendo-textbox>
                        <kendo-formerror> Error: First Name is required </kendo-formerror>
                    </kendo-formfield>

                    <kendo-formfield orientation="horizontal">
                        <kendo-label [for]="lastName" text="Asset Classification Description"> </kendo-label>
                        <kendo-textbox formControlName="lastName" [clearButton]="true" #lastName required> </kendo-textbox>
                        <kendo-formerror> Error: Last Name is required </kendo-formerror>
                    </kendo-formfield>
                    </div>
                    <div style="display: flex;justify-content: space-between;">
                        <kendo-formfield orientation="horizontal">
                            <kendo-label class="k-label" [for]="firstName" text="Asset Classification Code"> </kendo-label>
                            <kendo-textbox formControlName="firstName" [clearButton]="false" #firstName required> </kendo-textbox>
                            <kendo-formerror> Error: First Name is required </kendo-formerror>
                        </kendo-formfield>
    
                        <kendo-formfield orientation="horizontal">
                            <kendo-label [for]="lastName" text="Asset Classification Description"> </kendo-label>
                            <kendo-textbox formControlName="lastName" [clearButton]="true" #lastName required> </kendo-textbox>
                            <kendo-formerror> Error: Last Name is required </kendo-formerror>
                        </kendo-formfield>
                        </div>
                    <kendo-formfield orientation="horizontal">
                        <kendo-label [for]="email" [text]="customMsgService.translate('email')"> </kendo-label>
                        <kendo-textbox formControlName="email" [clearButton]="true" #email required> </kendo-textbox>
                        <kendo-formerror *ngIf="formGroup.controls.email.errors?.required"> Error: Email is required </kendo-formerror>
                        <kendo-formerror *ngIf="formGroup.controls.email.errors?.email"> Error: Not valid email format </kendo-formerror>
                    </kendo-formfield>

                    <kendo-formfield orientation="horizontal">
                        <kendo-label [for]="phoneNumber" [text]="customMsgService.translate('phone')"> </kendo-label>
                        <kendo-maskedtextbox #phoneNumber formControlName="phoneNumber" [mask]="phoneNumberMask"> </kendo-maskedtextbox>
                        <kendo-formerror>
                            <div *ngIf="formGroup.controls.phoneNumber.errors">Error: Not a valid phone number format</div>
                            <div *ngIf="formGroup.controls.phoneNumber.errors?.required">Error: Phone number is required</div>
                        </kendo-formerror>
                    </kendo-formfield>

                    <kendo-formfield orientation="horizontal">
                        <kendo-label [for]="country" [text]="customMsgService.translate('country')"> </kendo-label>
                        <kendo-autocomplete
                            #country
                            [data]="countries"
                            formControlName="country"
                            [valueField]="'name'"
                            [placeholder]="'e.g. Denmark'"
                        >
                        </kendo-autocomplete>
                    </kendo-formfield>

                    <kendo-formfield orientation="horizontal">
                        <kendo-label [for]="biography" [text]="customMsgService.translate('biography')"> </kendo-label>
                        <kendo-editor #biography formControlName="biography">
                            <kendo-toolbar>
                                <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                                <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                            </kendo-toolbar>
                        </kendo-editor>
                    </kendo-formfield>

                    <kendo-formfield orientation="horizontal">
                        <kendo-label [for]="directory" [text]="customMsgService.translate('directory')"> </kendo-label>
                        <kendo-switch #directory formControlName="directory"> </kendo-switch>
                        <kendo-formerror> Error: This field is required </kendo-formerror>
                    </kendo-formfield>

                    <kendo-formfield orientation="horizontal">
                        <label class="k-label"> {{ customMsgService.translate('team') }} </label>
                        <ul class="k-radio-list k-list-horizontal">
                            <li class="k-radio-item">
                                <input type="radio" #tigerTeam value="tiger" kendoRadioButton formControlName="team" />
                                <kendo-label class="k-radio-label" [for]="tigerTeam" text="Tiger Team"> </kendo-label>
                            </li>
                            <li class="k-radio-item">
                                <input type="radio" #lemonTeam value="lemon" kendoRadioButton formControlName="team" />
                                <kendo-label class="k-radio-label" [for]="lemonTeam" text="Lemon Team"> </kendo-label>
                            </li>
                            <li class="k-radio-item">
                                <input type="radio" #organicTeam value="organic" kendoRadioButton formControlName="team" />
                                <kendo-label class="k-radio-label" [for]="organicTeam" text="Organic Team"> </kendo-label>
                            </li>
                            <li class="k-radio-item">
                                <input type="radio" #oceanTeam value="ocean" kendoRadioButton formControlName="team" />
                                <kendo-label class="k-radio-label" [for]="oceanTeam" text="Ocean Team"> </kendo-label>
                            </li>
                        </ul>
                    </kendo-formfield>

                    <hr />
                    <div class="k-form-buttons">
                        <button kendoButton (click)="cancelChanges()">
                            {{ customMsgService.translate('cancel') }}
                        </button>
                        <button kendoButton [primary]="true" [disabled]="formGroup.invalid || formGroup.pristine" (click)="saveChanges()">
                            {{ customMsgService.translate('saveChanges') }}
                        </button>
                    </div>
                </fieldset>
            </form>
        </div>
    </div>
</div>
