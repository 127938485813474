import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgModule, LOCALE_ID, NgZone } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ProfileComponent } from './components/profile/profile.component';
import { InfoComponent } from './components/info/info.component';
import { HeaderComponent } from './header/header.component';
import { CustomMessagesService } from './services/custom-messages.service';
import { AssetsManagmentComponent } from './components/assets-managment/assets-managment.component';

import { ExcelModule, GridModule, PDFModule } from '@progress/kendo-angular-grid';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { EditService, SchedulerModule } from '@progress/kendo-angular-scheduler';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { EditorModule } from '@progress/kendo-angular-editor';
import { FileSelectModule } from '@progress/kendo-angular-upload';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { IntlModule } from '@progress/kendo-angular-intl';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { MessageService } from '@progress/kendo-angular-l10n';
import { ToolsModule } from './tools/tools.module';
const drawerRoutes = [
    { path: 'dashboard', component: DashboardComponent },
    { path: 'profile', component: ProfileComponent },
    { path: 'info', component: InfoComponent },
    { path: 'assets',component:AssetsManagmentComponent},
    { path: 'input',component:InputComponent},
    { path: 'register',component:RagisterComponent},
    { path: 'rolepermission',component:RolepermissionComponent},
    { path: 'userrole',component:UserroleComponent},
    { path: 'user-shift',component:UserShiftComponent},
    { path: 'asset-classification',component:AssetClassificationComponent},
    { path: 'asset-type-code-classification',component:AssetTypeCodeClassificationComponent},
    { path: 'asset-standardization',component:AssetStandardizationComponent},
    { path: 'contractor-vendor',component:ContractorVendorComponent},
];

import 'hammerjs';

import '@progress/kendo-angular-intl/locales/en/all';
import '@progress/kendo-angular-intl/locales/es/all';
import '@progress/kendo-angular-intl/locales/fr/all';
import { LoginUserComponent } from './components/login-user/login-user.component';
import { RagisterComponent } from './components/ragister/ragister.component';
import { DashboardtableComponent } from './components/dashboardtable/dashboardtable.component';
import { InputComponent } from './components/input/input.component';
import { RolepermissionComponent } from './components/rolepermission/rolepermission.component';
import { UserroleComponent } from './components/userrole/userrole.component';
import { UserShiftComponent } from './components/user-shift/user-shift.component';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { AssetClassificationComponent } from './components/asset-classification/asset-classification.component';
import { AssetTypeCodeClassificationComponent } from './components/asset-type-code-classification/asset-type-code-classification.component';
import { AssetStandardizationComponent } from './components/asset-standardization/asset-standardization.component';
import { ContractorVendorComponent } from './components/contractor-vendor/contractor-vendor.component';

@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        ProfileComponent,
        HeaderComponent,
        InfoComponent,
        AssetsManagmentComponent,
        LoginUserComponent,
        RagisterComponent,
        DashboardtableComponent,
        InputComponent,
        RolepermissionComponent,
        UserroleComponent,
        UserShiftComponent,
        AssetClassificationComponent,
        AssetTypeCodeClassificationComponent,
        AssetStandardizationComponent,
        ContractorVendorComponent      
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        GridModule,
        PDFModule,
        ExcelModule,
        LabelModule,
        LayoutModule,
        SchedulerModule,
        ButtonsModule,
        EditorModule,
        FileSelectModule,
        HttpClientModule,
        HttpClientJsonpModule,
        ChartsModule,
        IntlModule,
        InputsModule,
        DropDownsModule,
        RouterModule.forRoot(drawerRoutes),
        NotificationModule,
        DateInputsModule,
        ToolsModule
    ],
   
    providers: [
        { provide: MessageService, useClass: CustomMessagesService },
        { provide: LOCALE_ID, useValue: 'en-US' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}

