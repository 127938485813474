<!-- main app container -->
<div class="card m-3">
	<h5 class="card-header">User Registration</h5>
	<div class="card-body register-form-body">
		<form [formGroup]="registerForm" (ngSubmit)="onSubmit()">

			<div class="form-row">
				<div class="form-group col-5">
					<label>Staff Name</label>
					<input kendoTextBox formControlName="firstName" class="form-control" [ngClass]="{ 'ng-invalid ng-touched': submitted && f.firstName.errors }" />
					<div *ngIf="submitted && f.firstName.errors" class="k-tooltip-validation">
						<div *ngIf="f.firstName.errors.required">Staff Name is required</div>
					</div>
				</div>
				<div class="form-group col-5">
					<label>Username (email)</label>
					<input kendoTextBox formControlName="lastName" class="form-control" [ngClass]="{ 'ng-invalid ng-touched': submitted && f.lastName.errors }" />
					<div *ngIf="submitted && f.lastName.errors" class="k-tooltip-validation">
						<div *ngIf="f.lastName.errors.required">Username is required</div>
					</div>
				</div>
			</div>

			<div class="form-row">
				<div class="form-group col-5">
					<label>Gender</label>
					<kendo-dropdownlist formControlName="gender" [data]="genders" class="form-control" [ngClass]="{ 'ng-invalid ng-touched': submitted && f.gender.errors }"></kendo-dropdownlist>
					<div *ngIf="submitted && f.gender.errors" class="k-tooltip-validation">
						<div *ngIf="f.gender.errors.required">Gender is required</div>
					</div>
				</div>
				<div class="form-group col-5">
					<label>Office No.</label>
					<input kendoTextBox type="text" placeholder="Office Number" formControlName="phone" class="form-control" [ngClass]="{ 'ng-invalid ng-touched': submitted && f.phone.errors }" />
					<div *ngIf="submitted && f.phone.errors" class="k-tooltip-validation">
						<div *ngIf="f.phone.errors.required">Office Number is required</div>
						<div *ngIf="f.phone.errors.minlength">Office Number must be at least 10 characters</div>
					</div>
				</div>
			</div>

			<div class="form-row">
				<div class="form-group col-5">
					<label>Company Email</label>
					<input kendoTextBox formControlName="firstName" class="form-control" [ngClass]="{ 'ng-invalid ng-touched': submitted && f.firstName.errors }" />
					<div *ngIf="submitted && f.firstName.errors" class="k-tooltip-validation">
						<div *ngIf="f.firstName.errors.required">Company Email is required</div>
					</div>
				</div>
				<div class="form-group col-5">
					<label>Mobile Number</label>
					<input kendoTextBox type="text" formControlName="phone" class="form-control" [ngClass]="{ 'ng-invalid ng-touched': submitted && f.phone.errors }" />
					<div *ngIf="submitted && f.phone.errors" class="k-tooltip-validation">
						<div *ngIf="f.phone.errors.required">Mobile is required</div>
						<div *ngIf="f.phone.errors.minlength">Mobile number must be at least 10 characters</div>
					</div>
				</div>
			</div>

			<div class="form-row">
				<div class="form-group col-5">
					<label>Date of joining</label>
					<kendo-datepicker [(value)]="value"></kendo-datepicker>
					<!-- <input kendoTextBox type="text" formControlName="date" class="form-control" [ngClass]="{ 'ng-invalid ng-touched': submitted && f.date.errors }" />
					<div *ngIf="submitted && f.date.errors" class="k-tooltip-validation">
						<div *ngIf="f.date.errors.required">This field is required</div>
					</div> -->
				</div>
				<div class="form-group col-5">
					<label>Login User Type</label>
					<kendo-dropdownlist formControlName="loginuser" [data]="loginusers" class="form-control" [ngClass]="{ 'ng-invalid ng-touched': submitted && f.loginuser.errors }"></kendo-dropdownlist>
					<div *ngIf="submitted && f.loginuser.errors" class="k-tooltip-validation">
						<div *ngIf="f.loginuser.errors.required">User type is required</div>
					</div>
				</div>
			</div>

			<div class="form-row">
				<div class="form-group col-5">
					<label>Status</label>
					<kendo-dropdownlist formControlName="status" [data]="statuss" class="form-control"></kendo-dropdownlist>
				</div>
				<div class="form-group col-5">
					<label>Nationality</label>
					<kendo-dropdownlist formControlName="nationality" [itemDisabled]="itemDisabled" [data]="nationalitys" class="form-control" [ngClass]="{ 'ng-invalid ng-touched': submitted && f.nationality.errors }"></kendo-dropdownlist>
					<div *ngIf="submitted && f.nationality.errors" class="k-tooltip-validation">
						<div *ngIf="f.nationality.errors.required">Nationality is required</div>
					</div>
				</div>
			</div>

			<div class="form-row ">
				<div class="form-group col-5">
					<label>Designation</label>
					<kendo-dropdownlist formControlName="designation" [itemDisabled]="itemDisabled" [data]="designations" class="form-control" [ngClass]="{ 'ng-invalid ng-touched': submitted && f.designation.errors }"></kendo-dropdownlist>
					<div *ngIf="submitted && f.designation.errors" class="k-tooltip-validation">
						<div *ngIf="f.designation.errors.required">Designation is required</div>
					</div>
				</div>
				<div class="form-group col-5">
					<label class="k-label" style="margin-top: 28px;">Central Pool &nbsp;&nbsp;
						<input type="checkbox" kendoCheckBox />
					</label>
				</div>
			</div>

			<div class="form-row">
				<div class="form-group col-5">
					<label>Contractor</label>
					<input kendoTextBox formControlName="firstName" class="form-control" />
				</div>
				<div class="form-group col-5">
					<label>Competency</label>
					<kendo-dropdownlist formControlName="Competency" [itemDisabled]="itemDisabled" [data]="Competencys" class="form-control" [ngClass]="{ 'ng-invalid ng-touched': submitted && f.Competency.errors }"></kendo-dropdownlist>
					<div *ngIf="submitted && f.Competency.errors" class="k-tooltip-validation">
						<div *ngIf="f.Competency.errors.required">Competency is required</div>
					</div>
				</div>
			</div>

			<div class="form-row">
				<div class="form-group col-5">
					<label>Grade</label>
					<kendo-dropdownlist formControlName="grade" [itemDisabled]="itemDisabled" [data]="grades" class="form-control"></kendo-dropdownlist>
				</div>
				<div class="form-group col-5">
					<label>Speciality</label>
					<kendo-dropdownlist formControlName="Speciality" [itemDisabled]="itemDisabled" [data]="Specialitys" class="form-control" [ngClass]="{ 'ng-invalid ng-touched': submitted && f.Speciality.errors }"></kendo-dropdownlist>
					<div *ngIf="submitted && f.Speciality.errors" class="k-tooltip-validation">
						<div *ngIf="f.Speciality.errors.required">Speciality is required</div>
					</div>
				</div>
			</div>

			<div class="form-row">
				<div class="form-group col-5">
					<label>Service</label>
					<kendo-dropdownlist formControlName="Service" [itemDisabled]="itemDisabled" [data]="Services" class="form-control" [ngClass]="{ 'ng-invalid ng-touched': submitted && f.Service.errors }"></kendo-dropdownlist>
					<div *ngIf="submitted && f.Service.errors" class="k-tooltip-validation">
						<div *ngIf="f.Service.errors.required">Service is required</div>
					</div>
				</div>
				<div class="form-group col-5">
					<label>Labour Cost Per Week</label>
					<input kendoTextBox formControlName="Labour" class="form-control" [ngClass]="{ 'ng-invalid ng-touched': submitted && f.Labour.errors }" />
					<div *ngIf="submitted && f.Labour.errors" class="k-tooltip-validation">
						<div *ngIf="f.Labour.errors.required">Labour Cost is required</div>
					</div>
				</div>
			</div>

			<div class="form-row">
				<div class="form-group col-5">
					<label>Company Name</label>
					<input kendoTextBox formControlName="Company" class="form-control" [ngClass]="{ 'ng-invalid ng-touched': submitted && f.Company.errors }" />
					<div *ngIf="submitted && f.Company.errors" class="k-tooltip-validation">
						<div *ngIf="f.Company.errors.required">Company Name is required</div>
					</div>
				</div>
				<div class="form-group col-5">
					<label>Location</label>
					<input kendoTextBox formControlName="Location" class="form-control" [ngClass]="{ 'ng-invalid ng-touched': submitted && f.Location.errors }" />
					<div *ngIf="submitted && f.Location.errors" class="k-tooltip-validation">
						<div *ngIf="f.Location.errors.required">Location is required</div>
					</div>
				</div>
			</div>
			
            <div class="mt-4 mb-4">
                <h2 class="font-weight-bold pl-5">Facility details</h2>
            </div>

            <div class="form-row">
				<div class="form-group col-5">
					<label>Customer</label>
					<input kendoTextBox formControlName="Customer" class="form-control" [ngClass]="{ 'ng-invalid ng-touched': submitted && f.Customer.errors }" />
					<div *ngIf="submitted && f.Customer.errors" class="k-tooltip-validation">
						<div *ngIf="f.Customer.errors.required">Customer Name is required</div>
					</div>
				</div>
				<div class="form-group col-5">
					<label>User Role</label>
					<input kendoTextBox formControlName="User_Role" class="form-control" [ngClass]="{ 'ng-invalid ng-touched': submitted && f.User_Role.errors }" />
					<div *ngIf="submitted && f.User_Role.errors" class="k-tooltip-validation">
						<div *ngIf="f.User_Role.errors.required">User_Role is required</div>
					</div>
				</div>
			</div>

            <div class="form-row">
				<div class="form-group col-5">
					<label>All Facilities</label>
					<input kendoTextBox formControlName="AllFacilities" class="form-control" [ngClass]="{ 'ng-invalid ng-touched': submitted && f.AllFacilities.errors }" />
					<div *ngIf="submitted && f.AllFacilities.errors" class="k-tooltip-validation">
						<div *ngIf="f.AllFacilities.errors.required">AllFacilities Name is required</div>
					</div>
				</div>
				<div class="form-group col-5">
					<label>Selected Facilities</label>
					<input kendoTextBox formControlName="SelectedFacilities" class="form-control" [ngClass]="{ 'ng-invalid ng-touched': submitted && f.SelectedFacilities.errors }" />
					<div *ngIf="submitted && f.SelectedFacilities.errors" class="k-tooltip-validation">
						<div *ngIf="f.SelectedFacilities.errors.required">SelectedFacilities is required</div>
					</div>
				</div>
			</div>

            <div class="form-row">
				<div class="form-group col-5">
					<label>Select User Role</label>
					<input kendoTextBox formControlName="SelectUserRole" class="form-control" [ngClass]="{ 'ng-invalid ng-touched': submitted && f.SelectUserRole.errors }" />
					<div *ngIf="submitted && f.SelectUserRole.errors" class="k-tooltip-validation">
						<div *ngIf="f.SelectUserRole.errors.required">SelectUserRole Name is required</div>
					</div>
				</div>
				<div class="form-group col-5">
					
				</div>
			</div>

			<div class="text-center mt-3 mb-4">
				<button kendoButton primary="true" class="mr-2">Save</button>
				<button kendoButton primary="true" class="mr-2">Save and add new</button>
				<button kendoButton primary="true" class="mr-2">Delete</button>
				<button kendoButton primary="true" class="mr-2">Export</button>
				<button kendoButton primary="true" class="mr-2">Import New</button>
				<button kendoButton primary="true" class="mr-2">Import Update</button>
				<button kendoButton type="reset" (click)="onReset()">Reset</button>
			</div>
		</form>
	</div>
</div>