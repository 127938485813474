<div class="info-page main-content">
    <div class="content">

        <div class="section-1">
            <h1>Kendo UI for Angular</h1>
            <h2>Build Professional-Grade Angular UI</h2>

            <a class="github-link" target="_blank" href="https://github.com/telerik/kendo-angular/tree/master/examples-standalone/coffee-warehouse">
                <img src="assets/github-icon.svg" alt="github icon" />
                <span class="github-text"> {{ customMsgService.translate('getSource') }} </span>
            </a>
        </div>

        <div class="section-2">
            <p>{{ customMsgService.translate('demoInfo') }}</p>
        </div>

        <div class="section-3">
            <div>
                <div class="package-item">
                    <h3 class="package-title">
                        <a target="_blank" rel="noopener noreferrer" href="https://www.telerik.com/kendo-angular-ui/components/buttons/"
                            >Buttons</a
                        >
                    </h3>
                    <ul class="package-items">
                        <li class="component-link">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.telerik.com/kendo-angular-ui/components/buttons/button"
                                >Button</a
                            >
                        </li>
                        <li class="component-link">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.telerik.com/kendo-angular-ui/components/buttons/buttongroup"
                                >ButtonGroup</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
            <div>
                <div class="package-item">
                    <h3 class="package-title">
                        <a target="_blank" rel="noopener noreferrer" href="https://www.telerik.com/kendo-angular-ui/components/data-query/"
                            >Data Query</a
                        >
                    </h3>
                </div>
            </div>
            <div>
                <div class="package-item">
                    <h3 class="package-title">
                        <a target="_blank" rel="noopener noreferrer" href="https://www.telerik.com/kendo-angular-ui/components/charts/"
                            >Charts</a
                        >
                    </h3>
                    <ul class="package-items">
                        <li class="component-link">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.telerik.com/kendo-angular-ui/components/charts/chart"
                                >Chart</a
                            >
                        </li>
                        <li class="component-link">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.telerik.com/kendo-angular-ui/components/charts/series-types/bar"
                                >Column Bar</a
                            >
                        </li>
                        <li class="component-link">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.telerik.com/kendo-angular-ui/components/charts/series-types/line"
                                >Horizontal Line</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
            <div>
                <div class="package-item">
                    <h3 class="package-title">
                        <a target="_blank" rel="noopener noreferrer" href="https://www.telerik.com/kendo-angular-ui/components/labels/"
                            >Labels</a
                        >
                    </h3>
                    <ul class="package-items">
                        <li class="component-link">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.telerik.com/kendo-angular-ui/components/labels/label/"
                                >Label</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
            <div>
                <div class="package-item">
                    <h3 class="package-title">
                        <a target="_blank" rel="noopener noreferrer" href="https://www.telerik.com/kendo-angular-ui/components/dateinputs/"
                            >Date Inputs</a
                        >
                    </h3>
                    <ul class="package-items">
                        <li class="component-link">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.telerik.com/kendo-angular-ui/components/dateinputs/daterange/"
                                >DateRange</a
                            >
                        </li>
                        <li class="component-link">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.telerik.com/kendo-angular-ui/components/dateinputs/datetimepicker/"
                                >DateTimePicker</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
            <div>
                <div class="package-item">
                    <h3 class="package-title">
                        <a target="_blank" rel="noopener noreferrer" href="https://www.telerik.com/kendo-angular-ui/components/dialogs/"
                            >Dialogs</a
                        >
                    </h3>
                    <ul class="package-items">
                        <li class="component-link">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.telerik.com/kendo-angular-ui/components/dialogs/dialog"
                                >Dialog</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
            <div>
                <div class="package-item">
                    <h3 class="package-title">
                        <a target="_blank" rel="noopener noreferrer" href="https://www.telerik.com/kendo-angular-ui/components/dropdowns/"
                            >Dropdowns</a
                        >
                    </h3>
                    <ul class="package-items">
                        <li class="component-link">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.telerik.com/kendo-angular-ui/components/dropdowns/autocomplete"
                                >AutoComplete</a
                            >
                        </li>
                        <li class="component-link">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.telerik.com/kendo-angular-ui/components/dropdowns/dropdownlist"
                                >DropDownList</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
            <div>
                <div class="package-item">
                    <h3 class="package-title">
                        <a target="_blank" rel="noopener noreferrer" href="https://www.telerik.com/kendo-angular-ui/components/editor/"
                            >Editor</a
                        >
                    </h3>
                </div>
            </div>
            <div>
                <div class="package-item">
                    <h3 class="package-title">
                        <a target="_blank" rel="noopener noreferrer" href="https://www.telerik.com/kendo-angular-ui/components/forms/"
                            >Form</a
                        >
                    </h3>
                </div>
            </div>
            <div>
                <div class="package-item">
                    <h3 class="package-title">
                        <a target="_blank" rel="noopener noreferrer" href="https://www.telerik.com/kendo-angular-ui/components/grid/"
                            >Grid</a
                        >
                    </h3>
                </div>
            </div>
            <div>
                <div class="package-item">
                    <h3 class="package-title">
                        <a target="_blank" rel="noopener noreferrer" href="https://www.telerik.com/kendo-angular-ui/components/inputs/"
                            >Inputs</a
                        >
                    </h3>
                    <ul class="package-items">
                        <li class="component-link">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.telerik.com/kendo-angular-ui/components/inputs/checkbox"
                                >Checkbox</a
                            >
                        </li>
                        <li class="component-link">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.telerik.com/kendo-angular-ui/components/inputs/radiobutton/"
                                >RadioButton</a
                            >
                        </li>
                        <li class="component-link">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.telerik.com/kendo-angular-ui/components/inputs/textbox/"
                                >TextBox</a
                            >
                        </li>
                        <li class="component-link">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.telerik.com/kendo-angular-ui/components/inputs/textarea/"
                                >TextArea</a
                            >
                        </li>
                        <li class="component-link">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.telerik.com/kendo-angular-ui/components/inputs/maskedtextbox"
                                >MaskedTextBox</a
                            >
                        </li>
                        <li class="component-link">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.telerik.com/kendo-angular-ui/components/inputs/switch"
                                >Switch</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
            <div>
                <div class="package-item">
                    <h3 class="package-title">
                        <a target="_blank" rel="noopener noreferrer" href="https://www.telerik.com/kendo-angular-ui/components/notification/"
                            >Notification</a
                        >
                    </h3>
                </div>
            </div>
            <div>
                <div class="package-item">
                    <h3 class="package-title">
                        <a target="_blank" rel="noopener noreferrer" href="https://www.telerik.com/kendo-angular-ui/components/layout/"
                            >Layout</a
                        >
                    </h3>
                    <ul class="package-items">
                        <li class="component-link">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.telerik.com/kendo-angular-ui/components/layout/avatar"
                                >Avatar</a
                            >
                        </li>
                        <li class="component-link">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.telerik.com/kendo-angular-ui/components/layout/card"
                                >Card</a
                            >
                        </li>
                        <li class="component-link">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.telerik.com/kendo-angular-ui/components/layout/drawer"
                                >Drawer</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
            <div>
                <div class="package-item">
                    <h3 class="package-title">
                        <a target="_blank" rel="noopener noreferrer" href="https://www.telerik.com/kendo-angular-ui/components/scheduler/"
                            >Scheduler</a
                        >
                    </h3>
                </div>
            </div>
            <div>
                <div class="package-item">
                    <h3 class="package-title">
                        <a target="_blank" rel="noopener noreferrer" href="https://www.telerik.com/kendo-angular-ui/components/tooltip/"
                            >Tooltip</a
                        >
                    </h3>
                </div>
            </div>
            <div>
                <div class="package-item">
                    <h3 class="package-title">
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.telerik.com/kendo-angular-ui/components/uploads/upload/"
                            >Upload</a
                        >
                    </h3>
                </div>
            </div>

            <div>
                <div class="package-item">
                    <h3 class="package-title">
                        <a target="_blank" rel="noopener noreferrer" href="https://www.telerik.com/kendo-angular-ui/components/styling/"
                            >Styling</a
                        >
                    </h3>
                    <ul class="package-items">
                        <li class="component-link">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.telerik.com/kendo-angular-ui/components/styling/theme-default/"
                                >Default Theme</a
                            >
                        </li>
                        <li class="component-link">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.telerik.com/kendo-angular-ui/components/styling/theme-bootstrap/"
                                >Bootstrap Theme</a
                            >
                        </li>
                        <li class="component-link">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.telerik.com/kendo-angular-ui/components/styling/theme-bootstrap/"
                                >Material Theme</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="footer"></div>
</div>
