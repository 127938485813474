import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-ragister',
  templateUrl: './ragister.component.html',
  styleUrls: ['./ragister.component.scss']
})
export class RagisterComponent {
  public listItems: Array<{ text: string; value: number; inStock: boolean }> = [
    { text: "1", value: 1, inStock: false },
    { text: "2", value: 2, inStock: false },
    { text: "3", value: 3, inStock: false },
    { text: "4", value: 4, inStock: false },
    { text: "5", value: 5, inStock: false },
  ];

  public itemDisabled(itemArgs: { dataItem: any; index: number }) {
    return !itemArgs.dataItem.inStock;
  }

  registerForm: FormGroup;
  submitted = false;
  titles = ['Mr', 'Mrs', 'Miss', 'Ms'];
  genders = ['Male', 'Female', 'Others'];
  statuss = ['Active', 'Inactive'];
  loginusers = ['Select','Company User','External User','Facility User','Super Admin'];
  designations = ['Option 1','Option 2'];
  nationalitys = ['Malaysia','Australia','Thailand','India'];
  grades = ['Option 1','Option 2'];
  Competencys = ['Option 1','Option 2'];
  Services = ['Option 1','Option 2'];
  Specialitys = ['Option 1','Option 2'];



  constructor(private formBuilder: FormBuilder) { }

  public value: Date = new Date(2000, 2, 10);

  ngOnInit() {
      this.registerForm = this.formBuilder.group({
          firstName: ['', Validators.required],
          lastName: ['', Validators.required],
          email: ['', [Validators.required, Validators.email]],
          date: ['', Validators.required],
          phone: ['', [Validators.required, Validators.minLength(10)]],
          gender: ['', Validators.required],
          status: ['',Validators.required],
          loginuser:['',Validators.required],
          designation:['',Validators.required],
          nationality:['',Validators.required],
          grade:['',Validators.required],
          Competency:['',Validators.required],
          Service: ['',Validators.required],
          Speciality: ['',Validators.required],
          Labour:['',Validators.required],
          Company: ['',Validators.required],
          Location:['',Validators.required],
          Customer: ['',Validators.required],
          User_Role: ['',Validators.required],
          AllFacilities:['',Validators.required],
          SelectedFacilities: ['',Validators.required],
          SelectUserRole:['',Validators.required]
  
      }, {
          // validator: MustMatch('password', 'confirmPassword')
      });
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      if (this.registerForm.invalid) {
          return;
      }

      // display form values on success
      alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value, null, 4));
  }

  onReset() {
      this.submitted = false;
      this.registerForm.reset();
  }

}