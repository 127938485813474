import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { sampleProducts } from 'src/app/Data/table';

@Component({
  selector: 'app-asset-type-code-classification',
  templateUrl: './asset-type-code-classification.component.html',
  styleUrls: ['./asset-type-code-classification.component.scss']
})
export class AssetTypeCodeClassificationComponent implements OnInit {

  public column_asset_type=[
    {field:"ProductID",title:"Parametres",isFilter:true},
    {field:"ProductName",title:"Variation Rate", isFilter:true},
    {field:"FirstOrderedOn",title:"Effective From Date", isFilter:true}
  ]
  public gridDataassettype=sampleProducts;
  // constructor() { }

Asset_type_cl: FormGroup;
  submitted = false;
  maintenence_f = ['PPM','RI','PDM'];
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.Asset_type_cl = this.formBuilder.group({
      asset_code: ['', Validators.required],
      asset_dis: ['', Validators.required],
      asset_cla_dis: ['', [Validators.required]],
      asset_type_code: ['', Validators.required],
      maintenence_f: ['', Validators.required],
      equip_fun_d: ['', Validators.required],
      life_ex: ['', [Validators.required]],
      exp_l_s: ['', Validators.required], 
      qap_asswt_ser: ['', [Validators.required]],
      ppm_compl: ['', Validators.required],
      equip_up: ['', Validators.required],
      qap_up_tar: ['', Validators.required],
      effe_form: ['', [Validators.required]],
      effec_to: ['', Validators.required],
      asset_age_5: ['', Validators.required],
      asset_age_10: ['', [Validators.required]],
      asset_age_5g: ['', Validators.required],

    }, {
        // validator: MustMatch('password', 'confirmPassword')
    });
}

  // convenience getter for easy access to form fields
  get f() { return this.Asset_type_cl.controls; }

  onSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      if (this.Asset_type_cl.invalid) {
          return;
      }

      // display form values on success
      alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.Asset_type_cl.value, null, 4));
  }

  onReset() {
      this.submitted = false;
      this.Asset_type_cl.reset();
  }
}
