import { Employee } from 'src/app/models/employee.model';

export const employees: Employee[] = [
    {
        teamId: 3,
        id: '848e6002-8a92-447d-951b-1ffd5e695578',
        fullName: 'Sig Jeannel',
        jobTitle: 'Human Resources Assistant III',
        country: 'US',
        isOnline: true,
        rating: 3,
        target: 100,
        budget: 47601,
        phone: '(936) 9429601',
        address: '138 Buhler Avenue',
        imgId: 1,
        gender: 'M',
        selected: false
    },
    {
        teamId: 3,
        id: '19d18d40-0e64-4837-9420-92130a0ed253',
        fullName: 'Shelden Greyes',
        jobTitle: 'Operator',
        country: 'GB',
        isOnline: true,
        rating: 5,
        target: 40,
        budget: 12253,
        phone: '(343) 6656271',
        address: '2 Waxwing Point',
        imgId: 2,
        gender: 'M',
        selected: false
    },
    {
        teamId: 4,
        id: 'bebdc6eb-9179-484a-917d-2e16a23bfdfe',
        fullName: 'Megen Cody',
        jobTitle: 'Operator',
        country: 'BR',
        isOnline: true,
        rating: 1,
        target: 66,
        budget: 96183,
        phone: '(178) 2336256',
        address: '4082 Stephen Court',
        imgId: 6,
        gender: 'F',
        selected: false
    },
    {
        teamId: 1,
        id: '38b08b88-e482-46fc-8976-83590c02ec23',
        fullName: 'Clevey Thursfield',
        jobTitle: 'VP Quality Control',
        country: 'BR',
        isOnline: true,
        rating: 2,
        target: 58,
        budget: 54936,
        phone: '(277) 7415010',
        address: '1563 Glacier Hill Parkway',
        imgId: 5,
        gender: 'M',
        selected: false
    },
    {
        teamId: 2,
        id: '2aac53f8-b72d-4629-9082-6d8239a8fecf',
        fullName: 'Ruthi Baldini',
        jobTitle: 'Data Coordiator',
        country: 'BR',
        isOnline: true,
        rating: 3,
        target: 37,
        budget: 46572,
        phone: '(766) 5691615',
        address: '6 Laurel Avenue',
        imgId: 8,
        gender: 'F',
        selected: false
    },
    {
        teamId: 5,
        id: '1aa789e5-de01-406e-a2ee-cc5ce20f7e34',
        fullName: 'Annecorinne Morter',
        jobTitle: 'Professor',
        country: 'FR',
        isOnline: false,
        rating: 2,
        target: 35,
        budget: 37198,
        phone: '(807) 2524830',
        address: '106 Green Street',
        imgId: 3,
        gender: 'F',
        selected: false
    },
    {
        teamId: 2,
        id: 'd2ff1b02-3808-44aa-9056-3b5df34bf865',
        fullName: 'Gracia Punyer',
        jobTitle: 'Assistant Manager',
        country: 'ES',
        isOnline: true,
        rating: 4,
        target: 64,
        budget: 84752,
        phone: '(515) 9749536',
        address: '69 Brentwood Alley',
        imgId: 2,
        gender: 'F',
        selected: false
    },
    {
        teamId: 2,
        id: '26b2b760-27e8-47a6-81c2-07870d1b2b30',
        fullName: 'Duky Hurring',
        jobTitle: 'Account Executive',
        country: 'BR',
        isOnline: false,
        rating: 3,
        target: 61,
        budget: -1266,
        phone: '(897) 7202034',
        address: '39 Morning Circle',
        imgId: 3,
        gender: 'M',
        selected: false
    },
    {
        teamId: 1,
        id: '91c6b652-4206-4a0c-bac6-dc21283a72d7',
        fullName: 'Briana Shemelt',
        jobTitle: 'Professor',
        country: 'US',
        isOnline: false,
        rating: 3,
        target: 63,
        budget: -9308,
        phone: '(205) 2560799',
        address: '11 Walton Court',
        imgId: 2,
        gender: 'F',
        selected: false
    },
    {
        teamId: 4,
        id: '1e8289dc-2ef3-4045-ad6b-786d00368427',
        fullName: 'Lexis Mostin',
        jobTitle: 'Analyst Programmer',
        country: 'FR',
        isOnline: true,
        rating: 4,
        target: 81,
        budget: 38153,
        phone: '(903) 8388089',
        address: '38547 Westend Way',
        imgId: 4,
        gender: 'F',
        selected: false
    },
    {
        teamId: 2,
        id: '797387bd-c247-48b3-97b6-5e75791f8809',
        fullName: 'Felizio Gooda',
        jobTitle: 'GIS Technical Architect',
        country: 'DE',
        isOnline: true,
        rating: 3,
        target: 89,
        budget: 81585,
        phone: '(372) 2389397',
        address: '9 Summer Ridge Circle',
        imgId: 2,
        gender: 'M',
        selected: false
    },
    {
        teamId: 2,
        id: '24c541b0-4978-4072-84d0-abe94fcd0756',
        fullName: 'Aubry Oxberry',
        jobTitle: 'Financial Advisor',
        country: 'BR',
        isOnline: false,
        rating: 2,
        target: 3,
        budget: -6095,
        phone: '(665) 4176083',
        address: '06 Lerdahl Point',
        imgId: 10,
        gender: 'F',
        selected: false
    },
    {
        teamId: 1,
        id: 'd3416440-7411-42cc-a913-7dd319ca8311',
        fullName: 'Orly Glasbey',
        jobTitle: 'Environmental Tech',
        country: 'BR',
        isOnline: true,
        rating: 5,
        target: 63,
        budget: 63945,
        phone: '(449) 8482879',
        address: '4035 Porter Parkway',
        imgId: 6,
        gender: 'F',
        selected: false
    },
    {
        teamId: 4,
        id: '139066b5-60c5-4cf5-9afe-fb4e5558b087',
        fullName: 'Stephanus Culp',
        jobTitle: 'Cost Accountant',
        country: 'BR',
        isOnline: false,
        rating: 2,
        target: 60,
        budget: 10613,
        phone: '(148) 3124030',
        address: '57028 Moland Terrace',
        imgId: 8,
        gender: 'M',
        selected: false
    },
    {
        teamId: 2,
        id: 'eb844971-b97d-4f79-bd5a-a266fcfaaf70',
        fullName: 'Roseanna Janecek',
        jobTitle: 'Database Administrator IV',
        country: 'FR',
        isOnline: false,
        rating: 4,
        target: 97,
        budget: 77351,
        phone: '(125) 4421623',
        address: '21973 Beilfuss Alley',
        imgId: 4,
        gender: 'F',
        selected: false
    },
    {
        teamId: 1,
        id: '5cb391fe-4855-445c-a8b8-617c04d1d999',
        fullName: 'Weidar McCombe',
        jobTitle: 'Civil Engineer',
        country: 'FR',
        isOnline: true,
        rating: 1,
        target: 77,
        budget: 35924,
        phone: '(488) 7911627',
        address: '7 Dahle Terrace',
        imgId: 1,
        gender: 'M',
        selected: false
    },
    {
        teamId: 4,
        id: '4ba9ad7e-d8b7-40e7-b8cd-67a8e743a249',
        fullName: 'Evelin Spirritt',
        jobTitle: 'Analyst Programmer',
        country: 'BR',
        isOnline: false,
        rating: 2,
        target: 18,
        budget: 58552,
        phone: '(821) 9538078',
        address: '89418 Knutson Pass',
        imgId: 1,
        gender: 'M',
        selected: false
    },
    {
        teamId: 2,
        id: 'e4a31407-39d1-4ab7-aad1-4e026a8c42fa',
        fullName: 'Andria Helbeck',
        jobTitle: 'Nurse Practicioner',
        country: 'BR',
        isOnline: true,
        rating: 4,
        target: 53,
        budget: 72526,
        phone: '(387) 9614638',
        address: '8589 Vernon Drive',
        imgId: 8,
        gender: 'F',
        selected: false
    },
    {
        teamId: 1,
        id: 'cd050499-dbf1-4a43-86ab-54feaacef401',
        fullName: 'Mariellen Ravelus',
        jobTitle: 'Systems Administrator I',
        country: 'DE',
        isOnline: true,
        rating: 2,
        target: 22,
        budget: -6659,
        phone: '(300) 6741661',
        address: '707 Gale Hill',
        imgId: 9,
        gender: 'F',
        selected: false
    },
    {
        teamId: 3,
        id: 'fda1c419-c0b8-4278-94b0-2ebef6bda164',
        fullName: 'Corri Pergens',
        jobTitle: 'Help Desk Operator',
        country: 'BR',
        isOnline: true,
        rating: 2,
        target: 74,
        budget: 12376,
        phone: '(769) 7145603',
        address: '856 Forest Crossing',
        imgId: 3,
        gender: 'F',
        selected: false
    },
    {
        teamId: 3,
        id: '4a962db9-136e-4fcc-a683-2f961e27fb6e',
        fullName: 'Friedrick Macknish',
        jobTitle: 'Human Resources Assistant II',
        country: 'FR',
        isOnline: true,
        rating: 2,
        target: 38,
        budget: 97848,
        phone: '(196) 4156385',
        address: '157 Talisman Trail',
        imgId: 9,
        gender: 'M',
        selected: false
    },
    {
        teamId: 4,
        id: 'fa47e456-83c4-4fdc-b57b-07effb279967',
        fullName: 'Georgette Trevorrow',
        jobTitle: 'VP Accounting',
        country: 'FR',
        isOnline: true,
        rating: 3,
        target: 3,
        budget: 17327,
        phone: '(319) 1412549',
        address: '07 Marquette Point',
        imgId: 3,
        gender: 'F',
        selected: false
    },
    {
        teamId: 4,
        id: '67656444-fbcd-4813-84e7-1bdcd82f803e',
        fullName: 'Vanya Goalby',
        jobTitle: 'Senior Cost Accountant',
        country: 'FR',
        isOnline: false,
        rating: 2,
        target: 77,
        budget: 33584,
        phone: '(496) 7538982',
        address: '2192 Iowa Lane',
        imgId: 6,
        gender: 'F',
        selected: false
    },
    {
        teamId: 1,
        id: '05c098c2-9617-4e66-928a-e29b97297138',
        fullName: 'Abel Ansell',
        jobTitle: 'Actuary',
        country: 'US',
        isOnline: false,
        rating: 4,
        target: 52,
        budget: 12312,
        phone: '(571) 9908377',
        address: '4355 Iowa Parkway',
        imgId: 4,
        gender: 'M',
        selected: false
    },
    {
        teamId: 2,
        id: '3b263e99-51bf-4461-bb20-d4303d326cd9',
        fullName: 'Odille Barus',
        jobTitle: 'Speech Pathologist',
        country: 'FR',
        isOnline: true,
        rating: 4,
        target: 60,
        budget: 46911,
        phone: '(974) 1137672',
        address: '496 Lotheville Avenue',
        imgId: 8,
        gender: 'F',
        selected: false
    },
    {
        teamId: 11,
        id: 'c09ddfaf-569e-4a75-8e53-1be27cf25927',
        fullName: '0001',
        jobTitle: 'mr. ram',
        country: 'FR',
        isOnline: true,
        rating: 4,
        target: 20,
        budget: 94258,
        phone: 'tap not working',
        address: 'done',
        imgId: 6,
        gender: 'M',
        selected: false
    },
    {
        teamId: 12,
        id: 'a48abbc9-6143-4f77-a720-0b76ad862a9a',
        fullName: '0002',
        jobTitle: 'ms. neeta',
        country: 'FR',
        isOnline: true,
        rating: 5,
        target: 0,
        budget: 65359,
        phone: 'window broken',
        address: 'work in proress',
        imgId: 5,
        gender: 'F',
        selected: false
    },
    {
        teamId: 13,
        id: 'd8816aee-4230-4392-a045-0a9c297451f7',
        fullName: '0003',
        jobTitle: 'mr. mayank',
        country: 'US',
        isOnline: true,
        rating: 5,
        target: 80,
        budget: 13246,
        phone: 'lift is blocked',
        address: 'pending',
        imgId: 8,
        gender: 'M',
        selected: false
    },
    {
        teamId: 14,
        id: '9f5af32c-fb0d-4449-a658-f2c7f182b63a',
        fullName: '0004',
        jobTitle: 'mr. john',
        country: 'FR',
        isOnline: false,
        rating: 3,
        target: 17,
        budget: 56472,
        phone: 'washing area is dirty',
        address: 'pending',
        imgId: 9,
        gender: 'M',
        selected: false
    },
    {
        teamId: 11,
        id: 'c09ddfaf-569e-4a75-8e53-1be27cf25927',
        fullName: '0005',
        jobTitle: 'mr. ram',
        country: 'FR',
        isOnline: true,
        rating: 4,
        target: 20,
        budget: 94258,
        phone: 'tap not working',
        address: 'done',
        imgId: 6,
        gender: 'M',
        selected: false
    },
    {
        teamId: 12,
        id: 'a48abbc9-6143-4f77-a720-0b76ad862a9a',
        fullName: '0006',
        jobTitle: 'ms. neeta',
        country: 'FR',
        isOnline: true,
        rating: 5,
        target: 0,
        budget: 65359,
        phone: 'window broken',
        address: 'work in proress',
        imgId: 5,
        gender: 'F',
        selected: false
    },
    {
        teamId: 13,
        id: 'd8816aee-4230-4392-a045-0a9c297451f7',
        fullName: '0007',
        jobTitle: 'mr. mayank',
        country: 'US',
        isOnline: true,
        rating: 5,
        target: 80,
        budget: 13246,
        phone: 'lift is blocked',
        address: 'pending',
        imgId: 8,
        gender: 'M',
        selected: false
    },
    {
        teamId: 14,
        id: '9f5af32c-fb0d-4449-a658-f2c7f182b63a',
        fullName: '0008',
        jobTitle: 'mr. john',
        country: 'FR',
        isOnline: false,
        rating: 3,
        target: 17,
        budget: 56472,
        phone: 'washing area is dirty',
        address: 'pending',
        imgId: 9,
        gender: 'M',
        selected: false
    },
    ];
