import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
// import { leave_list } from 'src/app/Data/leave_list';
// import { summary_list } from 'src/app/Data/summary_list';
import { sampleProducts } from 'src/app/Data/table';

@Component({
  selector: 'app-user-shift',
  templateUrl: './user-shift.component.html',
  styleUrls: ['./user-shift.component.scss']
})
export class UserShiftComponent {
  // public leavegridData: any[] = leave_list;
  // public summarygridData: any[] = summary_list;
  public listItems: Array<{ text: string; value: number; inStock: boolean }> = [
    { text: "1", value: 1, inStock: false },
    { text: "2", value: 2, inStock: false },
    { text: "3", value: 3, inStock: false },
    { text: "4", value: 4, inStock: false },
    { text: "5", value: 5, inStock: false },
  ];
  public column1=[
    {field:"ProductID",title:"Leave From",isFilter:true},
    {field:"ProductName",title:"Leave to", isFilter:true},
    {field:"FirstOrderedOn",title:"No Of Days", isFilter:true}
  ]
  public column2=[
    {field:"ProductID",title:"Staff Name",isFilter:true},
    {field:"ProductName",title:"User Name", isFilter:true},
    {field:"FirstOrderedOn",title:"Leave From", isFilter:true},
    {field:"UnitPrice",title:"Leave to", isFilter:true},
    {field:"UnitPrice",title:"No Of Days", isFilter:true}
  ]
public gridData=sampleProducts;

  public itemDisabled(itemArgs: { dataItem: any; index: number }) {
    return !itemArgs.dataItem.inStock;
  }

  public value: Date = new Date(2000, 2, 10);
  public value2: Date = new Date(2000, 3, 10);
  public min: Date = new Date(2000, 2, 10, 2, 30);
  public max: Date = new Date(2002, 2, 10, 22, 15);
  public valuetime: Date = new Date(2000, 2, 10, 10, 0);

  user_shift_form: FormGroup;
  submitted = false;
  userroles = ['Super Admin','Company User', 'External User', 'Facility User'];
  status = ['Active','Inactive'];
  competencys = ['Management','Building System', 'Machanical', 'Electrical', 'Operations', 'Integrated Services', 'CSRM','IBCC','Housekeeping'];
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.user_shift_form = this.formBuilder.group({
      staff_name: ['', Validators.required],
      mob_no: ['', Validators.required],
      user_type: ['',Validators.required],
      design: ['', Validators.required],
      // todate: ['', Validators.required],
      // fromdate: ['', Validators.required],
      // brtime: ['',Validators.required],
      // leavef:['',Validators.required],
      // leavet:['',Validators.required]

    }, {
        // validator: MustMatch('password', 'confirmPassword')
    });
}

  // convenience getter for easy access to form fields
  get f() { return this.user_shift_form.controls; }

  onSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      if (this.user_shift_form.invalid) {
          return;
      }

      // display form values on success
      alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.user_shift_form.value, null, 4));
  }

  onReset() {
      this.submitted = false;
      this.user_shift_form.reset();
  }
}
