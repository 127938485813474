<div class="card m-3">
    <h5 class="card-header">Asset Standardization</h5>
    <div class="card-body register-form-body">
        <form [formGroup]="Asset_std" (ngSubmit)="onSubmit()">
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Asset Type Code</label>
                    <input kendoTextBox formControlName="asset_type_code" class="form-control"/>
                </div>
                <div class="form-group col-5">
                    <label>Asset Type Description</label>
                    <input kendoTextBox formControlName="asset_type_dis" class="form-control"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Model</label>
                    <input kendoTextBox formControlName="model" class="form-control"/>
                </div>
                <div class="form-group col-5">
                    <label>Manufacturer</label>
                    <input kendoTextBox formControlName="manuf" class="form-control"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Status</label>
                    <kendo-dropdownlist formControlName="status"  [data]="status" class="form-control">
                    </kendo-dropdownlist>
                </div>
                <div class="form-group col-5">
                    
                </div>
            </div>
            
            <div class="text-center mb-4 pt-3">
                <button kendoButton primary="true" class="mr-2">Save</button>
                <button kendoButton primary="true" class="mr-2">Save and add new</button>
                <button kendoButton type="reset" (click)="onReset()">Reset</button>
            </div>
        </form>
    </div>
</div>