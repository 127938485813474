  <div *ngIf="type=='login'" class="container-fluid" id="authentication-wrapper">
  <div id="welcome-message">
    <h4>Log in to continue </h4>
  </div>
  <div id="wrap">
    <img src="https://bit.ly/2udBml1" alt="profile picture">
  </div>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12">
      <kendo-textbox-container floatingLabel="Username">
        <input kendoTextBox type="email" />
      </kendo-textbox-container>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12">
      <kendo-textbox-container floatingLabel="Password">
        <input kendoTextBox type="password" />
      </kendo-textbox-container>
    </div>
    <!-- <div class="col-xs-12 col-sm-12 col-md-12">
      <kendo-textbox-container>
        <a class="text-primary" (click)="type='forgetpassword'">Forgotpassowrd</a>
      </kendo-textbox-container>
    </div> -->
    <div class="col-xs-12 col-sm-12 col-md-12">
      <button class="submit-btn btn btn-success" (click)="loginResponce(true)" > Login </button>
    </div>
  </div>
</div>


  <div class="container-fluid" id="authentication-wrapper" *ngIf="type=='forgetpassword'">
    <div id="welcome-message">
      <h4>Forgot Password</h4>
    </div>
    <div id="wrap">
      <img src="https://bit.ly/2udBml1" alt="profile picture">
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12">
        <kendo-textbox-container floatingLabel="Username">
          <input kendoTextBox type="email" />
        </kendo-textbox-container>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12">
        <button class="submit-btn btn btn-success"> Submit </button>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12">
        <kendo-textbox-container>
          <a class="text-primary" (click)="type='login'" style="padding: 20px; padding-top: 0;">Back To Login</a>
        </kendo-textbox-container>
      </div>
    </div>
  </div>