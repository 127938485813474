<div class="header header-bg">
    <div class="nav-container">
        <div class="menu-button">
            <span class="k-icon k-i-menu" (click)="onButtonClick()"></span>
        </div>
 
        <div class="title">
            <h1> {{ this.customMsgService.translate('warehouse') }} </h1>
            <span class="vl"></span>
            <h2> {{ selectedPage }} </h2>
        </div> 

      <kendo-avatar
            imageSrc="assets/bell-icon.png"
            shape="circle"
        >
        </kendo-avatar> 

        <kendo-avatar
            imageSrc="assets/user.jpg"
            shape="circle"
        >
        </kendo-avatar>

       

    </div>
</div>
