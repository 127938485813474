import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
// import { user_list } from 'src/app/Data/user_list';
import { sampleProducts } from 'src/app/Data/table';
@Component({
  selector: 'app-userrole',
  templateUrl: './userrole.component.html',
  styleUrls: ['./userrole.component.scss']
})
export class UserroleComponent {
  // public gridData: any[] = user_list;
  public listItems: Array<{ text: string; value: number; inStock: boolean }> = [
    { text: "1", value: 1, inStock: false },
    { text: "2", value: 2, inStock: false },
    { text: "3", value: 3, inStock: false },
    { text: "4", value: 4, inStock: false },
    { text: "5", value: 5, inStock: false },
  ];
  public column=[
    {field:"ProductID",title:"Role Name",isFilter:true},
    {field:"ProductName",title:"User Type", isFilter:true},
    {field:"FirstOrderedOn",title:"Competency", isFilter:true},
    {field:"UnitPrice",title:"Status", isFilter:true}
  ]
public gridData=sampleProducts;

  public itemDisabled(itemArgs: { dataItem: any; index: number }) {
    return !itemArgs.dataItem.inStock;
  }

  UserForm: FormGroup;
  submitted = false;
  userroles = ['Super Admin','Company User', 'External User', 'Facility User'];
  status = ['Active','Inactive'];
  competencys = ['Management','Building System', 'Machanical', 'Electrical', 'Operations', 'Integrated Services', 'CSRM','IBCC','Housekeeping'];
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.UserForm = this.formBuilder.group({
        role: ['', Validators.required],
        userrole: ['', Validators.required],
        status: ['', [Validators.required]],
        competency: ['', Validators.required],
        remark: ['', Validators.required],

    }, {
        // validator: MustMatch('password', 'confirmPassword')
    });
}

  // convenience getter for easy access to form fields
  get f() { return this.UserForm.controls; }

  onSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      if (this.UserForm.invalid) {
          return;
      }

      // display form values on success
      alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.UserForm.value, null, 4));
  }

  onReset() {
      this.submitted = false;
      this.UserForm.reset();
  }
}
