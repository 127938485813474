<div *ngIf="auth">
<app-header-component look="flat" (toggle)="toggleDrawer(drawer)" [selectedPage]="selected"></app-header-component>
<kendo-drawer-container>
    <kendo-drawer
        #drawer
        [items]="items"
        [mode]="mode"
        [mini]="mini"
        [miniWidth]="0"
        [expanded]="true"
        [animation]="true"
        [autoCollapse]="false"
        (select)="onSelect($event)"
    >
    <ng-template kendoDrawerItemTemplate let-item>
        <div class="k-level-{{ item.level }}">
          <span class="k-icon {{ item.icon }}"></span>
        </div>
        {{ item.text }}

        <span
          *ngIf="item.expanded && item.children"
          class="k-icon k-i-arrow-chevron-down"
          style="margin-left: auto; line-height: inherit"
        ></span>

        <span
          *ngIf="!item.expanded && item.children"
          class="k-icon k-i-arrow-chevron-right"
          style="margin-left: auto; line-height: inherit"
        ></span>
      </ng-template>
    </kendo-drawer>
    <kendo-drawer-content>
        <div style="padding: 1rem;">
        <router-outlet></router-outlet>
        </div>
    </kendo-drawer-content>
</kendo-drawer-container>
</div>
<div *ngIf="!auth">
     <app-login-user (loginStatus)="loginStatus($event)" style="height: 100vh;display: flex; background-repeat: no-repeat;
     background-size: 100% 100%; background-image: url('assets/login11.jpg');"></app-login-user>
</div>