export const menu = {
    parents: [
      {
        text: "Dashboard",
        icon: "k-i-js",
        children: false,
        selected: false,
        expanded: false,
        path:"/dashboard"
      },
      {
        text: "User Module",
        icon: "k-i-user",
        key:"assets_metadeta",
        expanded: false,
        children: true,
        selected: false,
        childrenList:[
              {
                text: "User Role",
                icon: "k-i-user",
                children: false,
                selected: false,
                expanded: false,
                level:1,
                path:"/userrole"
              },
              {
                text: "Role Screen Permission Mapping",
                icon: "k-i-globe-outline",
                children: false,
                selected: false,
                expanded: false,
                level:1,
                path:"/rolepermission"
              },
              {
                text: "User Registration",
                icon: "k-i-login",
                children: false,
                selected: false,
                expanded: false,
                level:1,
                path:"/register"
              },
             
              {
                text: "User Shift & Leave Details",
                icon: "k-i-accessibility",
                children: false,
                selected: false,
                expanded: false,
                level:1,
                path:"/user-shift"
              }
        ]
      },
      {
        text: "Asset Metadata",
        icon: "k-i-user",
        key:"assets_metadeta",
        expanded: false,
        children: true,
        selected: false,
        childrenList:[
              {
                text: "Asset Classification",
                icon: "k-i-globe-outline",
                children: false,
                selected: false,
                expanded: false,
                level:1,
                path:"/asset-classification"
              },
              {
                text: "Asset Type Code Classification",
                icon: "k-i-globe-outline",
                children: false,
                selected: false,
                expanded: false,
                level:1,
                path:"/asset-type-code-classification"
              },
              {
                text: "Asset Standardization",
                icon: "k-i-globe-outline",
                children: false,
                selected: false,
                expanded: false,
                level:1,
                path:"/asset-standardization"
              },
              {
                text: "Contractor And Vendor",
                icon: "k-i-globe-outline",
                children: false,
                selected: false,
                expanded: false,
                level:1,
                path:"/contractor-vendor"
              }
        ]
      }
    ]
  };