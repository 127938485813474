<!-- main app container -->
<div class="card m-3">
  <h5 class="card-header">Reset Password</h5>
  <div class="card-body">
      <form class="text-center">
        <div class="row">
   
          <div class="col-xs-12 col-sm-12 col-md-12">
            <kendo-textbox-container floatingLabel="Old Password">
              <input kendoTextBox type="password" />
            </kendo-textbox-container>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12">
            <kendo-textbox-container floatingLabel=" New Password">
              <input kendoTextBox type="password" />
            </kendo-textbox-container>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12">
            <kendo-textbox-container floatingLabel="Confirm Password">
              <input kendoTextBox type="password" />
            </kendo-textbox-container>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 mt-3">
            <button class="submit-btn btn btn-secondary mr-2" > Cancel </button>
            <button class="submit-btn btn btn-success" > Reset </button>
          </div>
        </div>
      </form>
  </div>
</div>