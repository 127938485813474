
<div class="card m-3">
  <h5 class="card-header">Role Screen Permission Mapping</h5>
  <div class="card-body">
      <div class="card-component">
          <kendo-grid
              [kendoGridBinding]="gridView"
              kendoGridSelectBy="id"
              [selectedKeys]="mySelection"
              [pageSize]="6"
              [pageable]="true"
              [sortable]="true"
              [groupable]="true"
              [reorderable]="true"
              [resizable]="true"
              [height]="230"
              [columnMenu]="{ filter: true }"
          >
              <ng-template kendoGridToolbarTemplate>
                  <kendo-textbox
                      [placeholder]="customMsgService.translate('gridSearch')"
                      kendoTextBox
                      (input)="onFilter($event.target.value)"
                  >
                  </kendo-textbox>
          
                 </ng-template>
              <kendo-grid-checkbox-column
                  [width]="45"
                  [headerClass]="{ 'text-center': true }"
                  [class]="{ 'text-center': true }"
                  [resizable]="false"
                  [columnMenu]="false"
                  showSelectAll="true"
              >
              </kendo-grid-checkbox-column>
             
                  <kendo-grid-column
                      field="fullName"
                      [title]="customMsgService.translate('contactName1')"
                      [width]="220"
                  >
                      <ng-template kendoGridCellTemplate let-dataItem>
                          <div class="customer-name"> {{ dataItem.fullName }} </div>
                      </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="Assign Permissions" width="350" filter="boolean">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <input type="checkbox" [checked]="dataItem.Discontinued"/> Add
                        <input type="checkbox" [checked]="dataItem.Discontinued" style="margin-left: 8px;"/> Delete
                        <input type="checkbox" [checked]="dataItem.Discontinued" style="margin-left: 8px;"/> Modification
                        <input type="checkbox" [checked]="dataItem.Discontinued" style="margin-left: 8px;"/> Print
                        <input type="checkbox" [checked]="dataItem.Discontinued" style="margin-left: 8px;"/> All
                    </ng-template>
                    
                </kendo-grid-column>
           
            
          
                  <kendo-grid-column field="phone1" [title]="customMsgService.translate('phone1')" [width]="130"> </kendo-grid-column>
                  <kendo-grid-column field="address1" [title]="customMsgService.translate('address1')" [width]="200"> </kendo-grid-column>
      
           
          </kendo-grid>
      </div>
      <div class="text-center" style="margin-top: 20px;">
        <button kendoButton primary="true" class="mr-2">Add Roles</button>
        <button kendoButton primary="true" class="mr-2">Save</button>
        <button kendoButton primary="true" class="mr-2">Save and Add new</button>
        <button kendoButton type="reset" >Reset</button>
    </div>
  </div>
</div>
