<div class="card m-3">
    <h5 class="card-header">Contractor And Vendor</h5>
    <div class="card-body register-form-body">
        <kendo-tabstrip (tabSelect)="onTabSelect($event)">
            <kendo-tabstrip-tab title="Contractor & Vendor" [selected]="true">
              <ng-template kendoTabContent>
                <form [formGroup]="contractor_vendor" (ngSubmit)="onSubmit()" class="pt-4">
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Contractor and Vendor Registration No</label>
                            <input kendoTextBox formControlName="con_ven_r" class="form-control"/>
                        </div>
                        <div class="form-group col-5">
                            <label>Contractor/Vendor Name</label>
                            <input kendoTextBox formControlName="con_ven_name" class="form-control"/>
                        </div>
                    </div>
                     <div class="form-row">
                        <div class="form-group col-5">
                            <label>Status</label>
                            <kendo-dropdownlist formControlName="status"  [data]="status" class="form-control">
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-group col-5">
                            <label>Specialization Detail</label>
                            <kendo-dropdownlist formControlName="spec_det"  [data]="spec_det" class="form-control">
                            </kendo-dropdownlist>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Address 1</label>
                            <kendo-textarea formControlName="add_1" class="form-control" [rows]="5" ></kendo-textarea>
                        </div>
                        <div class="form-group col-5">
                            <label>Address 2</label>
                            <kendo-textarea formControlName="add_2" class="form-control" [rows]="5" ></kendo-textarea>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Post Code</label>
                            <input kendoTextBox formControlName="post_code" class="form-control"/>
                        </div>
                        <div class="form-group col-5">
                            <label>State</label>
                            <input kendoTextBox formControlName="state" class="form-control"/>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Country</label>
                            <kendo-dropdownlist formControlName="country"  [data]="country" class="form-control">
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-group col-5">
                            <label>Phone No.</label>
                            <input kendoTextBox formControlName="phn_no" class="form-control"/>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>FAX No.</label>
                            <input kendoTextBox formControlName="fax_no" class="form-control"/>
                        </div>
                        <div class="form-group col-5">
                            <label>No. of User Access</label>
                            <input kendoTextBox formControlName="no_user_access" class="form-control"/>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Contact Person</label>
                            <input kendoTextBox formControlName="contact_person" class="form-control"/>
                        </div>
                        <div class="form-group col-5">
                            <label>Designation</label>
                            <input kendoTextBox formControlName="design" class="form-control"/>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Contact Number</label>
                            <input kendoTextBox formControlName="contact_no" class="form-control"/>
                        </div>
                        <div class="form-group col-5">
                            <label>Email</label>
                            <input kendoTextBox formControlName="email" class="form-control"/>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-11 p-3">
                            <label>Remark </label>
                            <kendo-textarea formControlName="remark" class="form-control" [rows]="5" ></kendo-textarea>
                        </div>
                    </div>
                </form>
              </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab title="Attachments">
              <ng-template kendoTabContent>
                
              </ng-template>
            </kendo-tabstrip-tab>
           
          </kendo-tabstrip>
    </div>
</div>

<div class="card m-3">
    <h5 class="card-header">Summary</h5>
    <div class="card-body">
          <app-table-grid
          [data]="gridData"
          [column]="column1"
          [size]="5"
        ></app-table-grid>
    </div>
</div>