<div class="card m-3">
    <h5 class="card-header">Recent Ticket Detail</h5>
    <div class="card-body">
        <div class="card-component">
            <kendo-grid
                [kendoGridBinding]="gridView"
                kendoGridSelectBy="id"
                [selectedKeys]="mySelection"
                [pageSize]="6"
                [pageable]="true"
                [sortable]="true"
                [groupable]="true"
                [reorderable]="true"
                [resizable]="true"
                [height]="480"
                [columnMenu]="{ filter: true }"
            >
                <ng-template kendoGridToolbarTemplate>
                    <kendo-textbox
                        [placeholder]="customMsgService.translate('gridSearch')"
                        kendoTextBox
                        (input)="onFilter($event.target.value)"
                    >
                    </kendo-textbox>
            
                   </ng-template>
                <kendo-grid-checkbox-column
                    [width]="45"
                    [headerClass]="{ 'text-center': true }"
                    [class]="{ 'text-center': true }"
                    [resizable]="false"
                    [columnMenu]="false"
                    showSelectAll="true"
                >
                </kendo-grid-checkbox-column>
               
                    <kendo-grid-column
                        field="fullName"
                        [title]="customMsgService.translate('contactName')"
                        [width]="220"
                    >
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="customer-name"> {{ dataItem.fullName }} </div>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column
                        field="jobTitle"
                        [title]="customMsgService.translate('jobTitle')"
                        [width]="220"
                    >
                    </kendo-grid-column>
             
              
            
                    <kendo-grid-column field="phone" [title]="customMsgService.translate('phone')" [width]="130"> </kendo-grid-column>
                    <kendo-grid-column field="address" [title]="customMsgService.translate('address')" [width]="200"> </kendo-grid-column>
           
             
            </kendo-grid>
        </div>
    </div>
  </div>
  