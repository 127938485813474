import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-asset-standardization',
  templateUrl: './asset-standardization.component.html',
  styleUrls: ['./asset-standardization.component.scss']
})
export class AssetStandardizationComponent implements OnInit {

Asset_std: FormGroup;
submitted = false;
status = ['Active','Inactive'];
constructor(private formBuilder: FormBuilder) { }

ngOnInit() {
  this.Asset_std = this.formBuilder.group({
    asset_type_code: ['', Validators.required],
    asset_type_dis: ['', Validators.required],
    model: ['', [Validators.required]],
    manuf: ['', Validators.required],
    status: ['', Validators.required],

  }, {
      // validator: MustMatch('password', 'confirmPassword')
  });
}

// convenience getter for easy access to form fields
get f() { return this.Asset_std.controls; }

onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.Asset_std.invalid) {
        return;
    }

    // display form values on success
    alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.Asset_std.value, null, 4));
}

onReset() {
    this.submitted = false;
    this.Asset_std.reset();
}

}
